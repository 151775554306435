import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Header.css'; // Import the custom CSS file

function Header() {
  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="navbar-brand">
          <img
            src="/images/logo.jpeg"
            alt="Logo"
            width="200"
            height="150"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/search/buy">Buy</Nav.Link>
            <Nav.Link as={Link} to="/search/sell">Sell</Nav.Link>
            <Nav.Link as={Link} to="/search/off-plan">Off Plan</Nav.Link>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/blog">Blogs</Nav.Link>
            {/* <Nav.Link as={Link} to="/contact">Contact</Nav.Link> */}
            <Nav.Link href="tel:+971585913585">
              <i className="bi bi-telephone-fill"></i> +971585913585
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
