import React, { useState } from 'react';
import { FaTag, FaBed, FaRulerCombined, FaBuilding, FaBusinessTime, FaMoneyBillWave } from 'react-icons/fa';
import './ProjectSummary.css'; // Use the existing CSS
import './ContactForm.css'; // Use the existing CSS for the form
import ContactForm from './ContactForm';

function ProjectSummary({ project }) {

  const { status, priceTag, bedroom, sqrFt, propertyType, price } = project;

  // Clean the price string and convert it to a number
  const numericPrice = parseFloat(price.replace(/,/g, ''));

  const [targetCurrency, setTargetCurrency] = useState('AED');
  const [convertedPrice, setConvertedPrice] = useState(numericPrice);

  if (!project) {
    return <p>Project data not available.</p>;
  }



  const currencyRates = {
    USD: 0.27,
    EUR: 0.23,
    GBP: 0.20,
    INR: 20,
    AED: 1
  };

  const convertCurrency = (aedPrice, currency) => {
    const rate = currencyRates[currency];
    return (aedPrice * rate).toFixed(2);
  };

  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setTargetCurrency(newCurrency);
    if (!isNaN(numericPrice)) {
      setConvertedPrice(convertCurrency(numericPrice, newCurrency));
    } else {
      console.error("Failed to convert price, invalid numeric value:", numericPrice);
    }
  };

  const formatBedrooms = (bedrooms) => (bedrooms ? bedrooms.join(', ') : null);

  return (
    <div className="project-contact-container">
      <div className="project-summary-half">
        <h2 className="project-title">Project Summary</h2>
        <table className="project-summary-table">
          <tbody>
            {status && (
              <tr>
                <td>
                  <div className="icon-text">
                    <FaBusinessTime className="icon" /> Project Status
                  </div>
                </td>
                <td>{status}</td>
              </tr>
            )}
            {priceTag && (
              <tr>
                <td>
                  <div className="icon-text">
                    <FaTag className="icon" /> Starting Price
                  </div>
                </td>
                <td>{priceTag}</td>
              </tr>
            )}
            {propertyType && (
              <tr>
                <td>
                  <div className="icon-text">
                    <FaBuilding className="icon" /> Property Type
                  </div>
                </td>
                <td>{propertyType}</td>
              </tr>
            )}
            {bedroom && (
              <tr>
                <td>
                  <div className="icon-text">
                    <FaBed className="icon" /> Bedroom(s)
                  </div>
                </td>
                <td>{formatBedrooms(bedroom)}</td>
              </tr>
            )}
            {sqrFt && (
              <tr>
                <td>
                  <div className="icon-text">
                    <FaRulerCombined className="icon" /> Area
                  </div>
                </td>
                <td>{sqrFt}</td>
              </tr>
            )}
            <tr>
              <td colSpan="2">
                <div className="icon-text">
                  <FaMoneyBillWave className="icon" /> Check Price in
                  <select onChange={handleCurrencyChange} value={targetCurrency} style={{ marginLeft: '10px' }}>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                    <option value= "AED">AED</option>
                  </select>
                  {convertedPrice !== null && (
                    <p style={{ display: 'inline', marginLeft: '10px' }}>
                      {convertedPrice} {targetCurrency}
                    </p>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ContactForm />
    </div>
  );
}

export default ProjectSummary;
