import React from 'react';
import './DownloadBrochureButton.css'; // Assuming you're including external CSS
import { FaFilePdf } from 'react-icons/fa'; 

const DownloadBrochureButton = ({ project }) => {
  const handleDownload = () => {
    const pdfPath = project.pdfPath;

    if (!pdfPath) {
      console.error("PDF path is not available");
      return;
    }

    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = pdfPath.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className="download-brochure-button" onClick={handleDownload}>
      <FaFilePdf/>Download Brochure PDF
    </button>
  );
};

export default DownloadBrochureButton;
