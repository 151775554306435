import React from 'react';
import './Partners.css';

// Sample images
const images = [
    '/images/DAMACCNEW.jpg', 
    '/images/EMAAR.jpg',
    '/images/SOBHAREALTYNEW.jpg',
    '/images/MERRAS.jpg'
];

function Partners() {
  return (
    <section className="partners">
      <h2>Partners</h2>
      <div className="partners-container">
        {images.map((image, index) => (
          <div className="partner-image" key={index}>
            <img src={image} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Partners;
