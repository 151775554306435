import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Header from './components/Header';
import PropertySearchView from './components/PropertyFinderComponents/PropertySearchView';
import ProjectDetailMainComponent from './components/ProjectDetailComponent/ProjectDetailMainComponent';
import Footer from './components/Footer';
import AboutUs from './components/AboutUsComponents/AboutUs';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search/:type" element={<PropertySearchView/>} />
        <Route path="/details/:name" element={<ProjectDetailMainComponent />} />
        <Route path="/about" element={<AboutUs />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
