import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './HeroComponent.css'; // Import the CSS file

function HomeComponent({ project }) {
    if (!project || !project.images || project.images.length === 0) {
        return null;
    }

    const imageUrl = project.images[0];

    return (
        <div
            className="hero"
            style={{ backgroundImage: `url(${imageUrl})` }} // Set background image
        >
            <div className="hero-content">
                <h1>{project.name}</h1>
                <h2>By {project.developer}</h2>
                <h2><FaMapMarkerAlt /> {project.location}</h2>
            </div>
        </div>
    );
}

export default HomeComponent;

