import React from 'react';
import './AboutUs.css';
// Sample image
const aboutUsImage = '/images/aboutUs.jpg'; // Replace with your image path

function AboutUs() {
  return (
    <section className="about-us">
      <div className="image-half">
        <img src={aboutUsImage} alt="About Us" />
      </div>
      <div className="text-half">
        <h2>About Us</h2>
        <p>
        At Sky Vista Realty, we believe in more than just selling properties—we are dedicated to providing unparalleled expertise, guidance, and exceptional service to our clients, helping them find their perfect homes in Dubai’s dynamic real estate market. With a deep understanding of the local market and a commitment to integrity, we ensure that every investment is a smart, secure, and lasting decision.
        </p>
        <p>
        Our team of experienced professionals is passionate about helping you navigate the complex and exciting world of real estate. Whether you're searching for a luxurious waterfront property, a sophisticated urban apartment, or a serene family home, we are here to offer the personalized service and local knowledge that you can rely on. At Sky Vista Realty, we don’t just aim to meet expectations—we strive to exceed them.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;
