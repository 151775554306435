import React from "react";
import './KeyHighlightsComponent.css'; // Updated the CSS import

function KeyHighlightsComponent({ project }) {
  const { key_highlights = [] } = project;

  return (
    <div className="container">
      <div className="key-highlights">
        <h2>Key Highlights</h2>
        {key_highlights.length > 0 ? (
          <ul>
            {key_highlights.map((highlight, index) => (
              <li
                key={index}
                dangerouslySetInnerHTML={{
                  __html: highlight.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"),
                }}
              ></li>
            ))}
          </ul>
        ) : (
          <p>No key highlights available for this property.</p>
        )}
      </div>
    </div>
  );
}

export default KeyHighlightsComponent;
