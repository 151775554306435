import React from 'react';
import './ProjectOverview.css';

function ProjectOverview({ project }) {
    return (
        <div className="ProjectContainer">
            <div className="ProjectOverview">
                <h2>Overview</h2>
                {project.Overview.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <div className="ProjectImage">
                <img src={project.images[1]} alt="Project" />
            </div>
        </div>
    );
}

export default ProjectOverview;

