import React from 'react';
import PropertyFinderHero from './PropertyFinderHero';
import PropertySearchFilterComponent from './PropertySearchFilterComponent';


function PropertySearchView() {
  return  <div className="propertySearch">
  <PropertyFinderHero /> {/* Use the Hero component */}
  <PropertySearchFilterComponent/>

</div>;
}

export default PropertySearchView;