import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function AboutUs() {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <h1 style={{ color: '#002349' }}>Sky Vista Realty</h1>
          <h2 className="text-secondary">“Where Realty Meets Reality”</h2>
          <p>
            At Sky Vista Realty, we believe in more than just selling properties—we are dedicated to providing unparalleled expertise, guidance, and exceptional service to our clients, helping them find their perfect homes in Dubai’s dynamic real estate market. With a deep understanding of the local market and a commitment to integrity, we ensure that every investment is a smart, secure, and lasting decision.
          </p>
          <p>
            Our team of experienced professionals is passionate about helping you navigate the complex and exciting world of real estate. Whether you're searching for a luxurious waterfront property, a sophisticated urban apartment, or a serene family home, we are here to offer the personalized service and local knowledge that you can rely on. At Sky Vista Realty, we don’t just aim to meet expectations—we strive to exceed them.
          </p>
          <p>
            Our clients trust us because we understand that real estate is more than just a transaction—it's about finding a place where memories are made and a foundation for the future is built. Whether you are buying, selling, or investing, we provide the expertise and support to ensure your real estate journey is smooth, transparent, and successful.
          </p>
          <p>
            At Sky Vista Realty, your goals are our priority, and we are here to turn your vision of a perfect home into reality. Let us guide you towards your next investment, with confidence and peace of mind. At Sky Vista Realty, your goals are not just our priority—they are our promise.
          </p>
        </div>
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
          <img src="/images/SobhaSiniyaIsland/Hero.jpg" alt="Sky Vista Realty" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
