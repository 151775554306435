import React from 'react';
import Hero from './HomeComponents/Hero';
import RecentProjects from './HomeComponents/RecentProjects';
import FeaturedSpace from './HomeComponents/FeaturedSpace';
import AboutUs from './HomeComponents/AboutUs';
import ContactUs from './HomeComponents/ContactUs';
import Partners from './HomeComponents/Partners';

function Home() {
  return  <div className="home">
  <Hero /> {/* Use the Hero component */}
  <RecentProjects/>
  <FeaturedSpace/>
  <AboutUs/>
  <ContactUs/>
  <Partners/>
</div>;
}

export default Home;