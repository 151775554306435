import React from 'react';
import './ContactForm.css'; // Assuming you save the CSS in this file

function ContactForm() {
    return (
        <div className="contact-us">
            <div className="form-half">
                <h2>Contact Us</h2>
                <form className="contact-form">
                    <input type="text" placeholder="Name" required />
                    <input type="email" placeholder="Email" required />
                    <input type="tel" placeholder="Phone Number" required />
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
