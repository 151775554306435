import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer-custom">
      <Container fluid>
        <Row>
          <Col lg={4} md={6} className="mb-3">
            <h5 className="footer-title">Quick Links</h5>
            <ul className="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/">Terms & Conditions</a></li>
              <li><a href="/">Privacy Policy</a></li>
              <li><a href="/">FAQ</a></li>
            </ul>
          </Col>
          <Col lg={4} md={6} className="mb-3">
            <h5 className="footer-title">Social Media</h5>
            <ul className="footer-links">
              <li><a href="https://www.instagram.com"><i className="bi bi-instagram"></i> Instagram</a></li>
              <li><a href="https://www.twitter.com"><i className="bi bi-twitter"></i> Twitter</a></li>
              <li><a href="https://www.linkedin.com"><i className="bi bi-linkedin"></i> LinkedIn</a></li>
              <li><a href="https://www.facebook.com"><i className="bi bi-facebook"></i> Facebook</a></li>
            </ul>
          </Col>
          <Col lg={4} md={12} className="mb-3">
            <h5 className="footer-title">Contact Us</h5>
            <ul className="footer-contact">
              <li><i className="bi bi-telephone"></i> +971585913585</li>
              <li><i className="bi bi-whatsapp"></i> +918112259260</li>
              <li><i className="bi bi-geo-alt"></i> Office No. 101-555, 
              Al Zahra Techno Centre,Dubai</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
