import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Hero.css'; // Import the custom CSS file for the Hero component

function Hero() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState('buy'); // Default to 'buy'

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    if (selectedType) {
      navigate(`/search/${selectedType}`);
    }
  };

  return (
    <section className="hero">
      <h1 className="hero-title">Where Realty Meets Reality</h1>

      <form className="hero-form" onSubmit={handleSubmit}>
        <label htmlFor="property-type">Looking For</label>
        <select name="property-type" className="hero-input">
          <option value="">Property Type</option>
          <option value="apartment">Apartment</option>
          <option value="villa">Villa</option>
          <option value="townhouse">Townhouse</option>
          <option value="commercial">Commercial</option>
        </select>
        <label htmlFor="location">Location</label>
        <input type="text" name="location" placeholder="Search Location" className="hero-input" />
        <label htmlFor="type">Type</label>
        <select name="type" className="hero-input" value={selectedType} onChange={handleTypeChange}>
          <option value="buy">Buy</option> {/* This option is the default selection */}
          <option value="sell">Sell</option>
          <option value="off-plan">Off-Plan</option>
        </select>
        <button type="submit" className="hero-button">Search</button>
      </form>
    </section>
  );
}

export default Hero;
