import React, { useEffect, useState } from 'react';
import './FeaturedSpace.css';

// Sample images
const images = [
    '/images/SobhaHartlandII/Interior.jpg',
    '/images/SobhaElwood/Interior.jpg',
    '/images/SobhaSiniyaIsland/Interior.jpg',
    '/images/SobhaSolis/Interior.jpg',
];

const getVisibleImages = (images, startIndex) => {
  const maxVisibleImages = 3;
  return images.slice(startIndex, startIndex + maxVisibleImages).concat(
    images.slice(0, maxVisibleImages - (images.length - startIndex))
  );
};

function FeaturedSpace() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 3000); // Slide every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  const visibleImages = getVisibleImages(images, currentImageIndex);

  return (
    <section className="featured-space">
      <div className="text-half">
        <h2>Our Featured Spaces</h2>
      </div>
      <div className="image-half">
        <div className="image-carousel">
          {visibleImages.map((image, index) => (
            <div className="image-wrapper" key={index}>
              <img src={image} alt={`Featured Space ${index + 1}`} />
              <div className="more-details-button">
                More Details
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FeaturedSpace;
