import React from 'react';
import './ContactUs.css';

function ContactUs() {
  return (
    <section className="contact-us">
      <div className="text-half">
        <h2>Get in Touch</h2>
        <p>
          We love to hear from you! Whether you have a question about our services,
          pricing, need to inquire, or anything else, our team is ready to answer all
          your questions.
        </p>
        <p>
          You can reach us at (+971)58-591-3585 or email us at info@skyvistarealtydxb.com
          Our office hours are Monday to Friday, 9 AM to 7 PM.
        </p>
      </div>
      <div className="form-half">
        <h2>Contact Us</h2>
        <form className="contact-form">
          <input type="text" placeholder="Name" required />
          <input type="email" placeholder="Email" required />
          <input type="tel" placeholder="Phone Number" required />
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
