import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../Card'; // Assumed path to your Card component
import projectData from '../projectdata.json'; // Assumed path to your data
import './PropertySearchFilterComponent.css'; // Assumed path to your CSS

const PropertySearchFilterComponent = () => {
  const { type } = useParams(); // Get the 'type' from the route
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;

  const defaultFilters = {
    developer: '',
    location: '',
    propertyType: '',
    bedroom: '',
    minPrice: '',
    maxPrice: '',
    minSqrFt: '',
    maxSqrFt: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const getUniqueValues = (key) => [...new Set(projectData.map(item => item[key]))];

  const uniqueDevelopers = getUniqueValues('developer');
  const uniqueLocations = getUniqueValues('location');
  const uniquePropertyTypes = getUniqueValues('propertyType');

  useEffect(() => {
    setFilters(defaultFilters);
    setCurrentPage(1);
    
    // Initial filter based on the type parameter
    const initialFilteredData = projectData.filter(item =>
      !type || (item.type && item.type.includes(type))
    );
    
    setFilteredData(initialFilteredData);
  }, [type]);

  useEffect(() => {
    const applyFilters = () => {
      const tempData = projectData.filter((item) => {
        const isTypeMatch = !type || (item.type && item.type.includes(type));
        const isDeveloperMatch = !filters.developer || item.developer.includes(filters.developer);
        const isLocationMatch = !filters.location || item.location.includes(filters.location);
        const isPropertyTypeMatch = !filters.propertyType || item.propertyType === filters.propertyType;
        const isBedroomMatch = !filters.bedroom || item.bedroom.includes(filters.bedroom);

        const isPriceMatch =
          (!filters.minPrice || parseFloat(item.price) >= parseFloat(filters.minPrice)) &&
          (!filters.maxPrice || parseFloat(item.price) <= parseFloat(filters.maxPrice));

        const isSqrFtMatch =
          (!filters.minSqrFt || parseFloat(item.sqrFtStart) >= parseFloat(filters.minSqrFt)) &&
          (!filters.maxSqrFt || parseFloat(item.sqrFtEnd) <= parseFloat(filters.maxSqrFt));

        return (
          isTypeMatch &&
          isDeveloperMatch &&
          isLocationMatch &&
          isPropertyTypeMatch &&
          isBedroomMatch &&
          isPriceMatch &&
          isSqrFtMatch
        );
      });

      setFilteredData(tempData);
    };
    
    applyFilters();
  }, [filters, type]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div className="property-search-view">
      <div className="filter-area">
        <select name="developer" onChange={handleFilterChange} value={filters.developer}>
          <option value="">Developer</option>
          {uniqueDevelopers.map(developer => (
            <option key={developer} value={developer}>{developer}</option>
          ))}
        </select>

        <select name="location" onChange={handleFilterChange} value={filters.location}>
          <option value="">Location</option>
          {uniqueLocations.map(location => (
            <option key={location} value={location}>{location}</option>
          ))}
        </select>

        <select name="propertyType" onChange={handleFilterChange} value={filters.propertyType}>
          <option value="">Property Type</option>
          {uniquePropertyTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>

        <select name="bedroom" onChange={handleFilterChange} value={filters.bedroom}>
          <option value="">Bedrooms</option>
          {[1, 1.5, 2, 2.5, 3, 4, 5, 6].map(size => (
            <option key={size} value={size}>{size}</option>
          ))}
        </select>

        <input type="number" name="minPrice" placeholder="Min Price" onChange={handleFilterChange} value={filters.minPrice} />
        <input type="number" name="maxPrice" placeholder="Max Price" onChange={handleFilterChange} value={filters.maxPrice} />
        <input type="number" name="minSqrFt" placeholder="Min Sq Ft" onChange={handleFilterChange} value={filters.minSqrFt} />
        <input type="number" name="maxSqrFt" placeholder="Max Sq Ft" onChange={handleFilterChange} value={filters.maxSqrFt} />
      </div>

      <div className="card-container">
        {currentItems.length ? (
          currentItems.map((item, index) => (
            <Card
              key={index}  // Use index as a fallback key
              images={item.images}
              name={item.name}
              developer={item.developer}
              location={item.location}
              propertyType={item.propertyType}
              bedroom={item.bedroom}
              sqrFt={item.sqrFt}
            />
          ))
        ) : (
          <p>No results found for {type?.replace('_', ' ')}</p>
        )}
      </div>

      <div className="pagination">
        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
          &laquo; Prev
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            disabled={currentPage === i + 1}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>
          Next &raquo;
        </button>
      </div>
    </div>
  );
};

export default PropertySearchFilterComponent;
