import React from 'react';
import { useParams } from 'react-router-dom';
import data from '../parentprojectdata.json';
import ProjectSummary from './ProjectSummary';
import HeroComponent from './HeroComponent';
import ProjectOverview from './ProjectOverview';
import DownloadBrochureButton from './DownloadBrochureButton';
import AmenitiesComponent from './AmenitiesComponent'; 
import LocationConnectivityComponent from './LocationConnectivityComponent';
import KeyHighlightsComponent from './KeyHighlightsComponent';

function ProjectDetailMainComponent() {
    const { name } = useParams();
    const project = data.find((p) => p.name === name);

    return (
        <div className="ProjectDetails">
            {project ? (
                <>
                    <HeroComponent project={project} />
                    <ProjectSummary project={project} />
                    <ProjectOverview project={project} />
                    <KeyHighlightsComponent project={project}/>
                    <DownloadBrochureButton project={project} />
                    <AmenitiesComponent amenities={project.amenities} />
                    <LocationConnectivityComponent project={project}/> 
                    
                </>
            ) : (
                <p>Project not found.</p>
            )}
        </div>
    );
}

export default ProjectDetailMainComponent;
