import React from 'react';
import { useParams } from 'react-router-dom';
import './PropertyFinderHero.css';

const PropertyFinderHero = () => {
  const { type } = useParams(); // assuming the route has a variable part like /hero/:heroName

  return (
    <div className="property-finder-hero">
      <div className="property-finder-hero-content">
        <h1> { type?.toUpperCase() } PROPERTIES</h1>
      </div>
    </div>
  );
};

export default PropertyFinderHero;
