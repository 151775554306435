// Import necessary modules
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RecentProjects.css';
import Card from '../Card';
import projectData from '../projectdata.json';

function RecentProjects() {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();

  // Load and filter data from JSON
  useEffect(() => {
    const recentProjects = projectData.filter(project => project.recentShow);
    setProjects(recentProjects);
  }, []);

    // Handler for Load More button click
    const handleLoadMore = () => {
      // Redirect to the desired route
      navigate('/search/off-plan');
    };

  return (
    <section className="recent-projects">
      <h2>LATEST OFF PLAN PROPERTIES</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <Card
            key={index}
            images={project.images}
            name={project.name}
            developer={project.developer}
            location={project.location}
            propertyType={project.propertyType}
            bedroom={project.bedroom}
            sqrFt={project.sqrFt}
          />
        ))}
        <div className="load-more">
          <button className="load-more-button" onClick={handleLoadMore}>Load More</button>
        </div>
      </div>
    </section>
  );
}

export default RecentProjects;
