// AmenitiesComponent.jsx
import React from "react";
import PropTypes from "prop-types";
import "./AmenitiesComponent.css";

function AmenitiesComponent({ amenities }) {
  // Function to chunk the array into pieces of up to 5 items
  const chunkArray = (arr, chunkSize) => {
    let result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunks = chunkArray(amenities, 5);

  const renderAmenities = () => {
    return chunks.map((chunk, chunkIndex) => (
      <ul className="AmenitiesList" key={chunkIndex}>
        {chunk.map((amenity, index) => (
          <li key={index}>{amenity}</li>
        ))}
      </ul>
    ));
  };

  return (
    <div className="AmenitiesSection">
      <h2 className="AmenitiesHeading">Amenities</h2>
      <div className="AmenitiesGrid">{renderAmenities()}</div>
    </div>
  );
}

AmenitiesComponent.propTypes = {
  amenities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AmenitiesComponent;
