import React from 'react';
import './LocationConnectivityComponent.css'; // Importing the CSS

function LocationConnectivityComponent({ project }) {
    return (
        <div className="LocationConnectivityContainer">
            <h2 className="LocationHeading">Location</h2>
            <div className="LocationConnectivity">
                {/* Image Section */}
                <div className="LocationImage">
                    <img 
                        src={project.locationImage} 
                        alt="Location Map" 
                    />
                </div>
                
                {/* Connectivity Section */}
                <div className="Connectivity">
                    <h2>Connectivity</h2>
                    <ul>
                        {project.locationConnectivity.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default LocationConnectivityComponent;
