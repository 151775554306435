import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBed, FaMapMarkerAlt, FaRulerCombined, FaBuilding, FaUser } from 'react-icons/fa'; // Importing additional icons
import './Card.css';

function Card({ images, name, developer, location, propertyType, bedroom, sqrFt }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleShowDetailsClick = () => {
    navigate(`/details/${name}`);
  };

  return (
    <div className="card">
      <div className="card-carousel">
        <img src={images[currentImageIndex]} alt={name} className="card-image" />
        <button onClick={handlePrevClick} className="carousel-button left">&lt;</button>
        <button onClick={handleNextClick} className="carousel-button right">&gt;</button>
      </div>
      <div className="card-content">
        <h3>{name}</h3>
        <p><FaUser /> {developer}</p>
        <div className="card-location">
          <FaMapMarkerAlt /> {location}
        </div>
        <div className="card-property-type">
          <FaBuilding /> {propertyType}
        </div>
        <div className="card-details">
          <FaBed /> {bedroom.join(', ')}
        </div>
        <div className="card-size">
          <FaRulerCombined /> {sqrFt}
        </div>
      </div>
      <div className="card-footer">
        <button className="show-detail" onClick={handleShowDetailsClick}>Show Details</button>
      </div>
    </div>
  );
}

export default Card;